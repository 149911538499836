import React from 'react'
import PropTypes from 'prop-types'
import titleStyles from './mods/title.module.css'

export default function Title({ text }) {
    return (
        <h1 className={titleStyles.title}>
          {text}  
        </h1>
    )
}

Title.propTypes = {
  text: PropTypes.string.isRequired,
}